import { useAttentionNeededInSession } from '../../common/src/hooks/itemHooks'
import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { useState } from 'react'

const compareItemsByMentionAndTimestamp = (a, b) => {
  // Items with unread mentions go first
  const aUnreadMention = itemHelper.hasUnreadMention(a)
  const bUnreadMention = itemHelper.hasUnreadMention(b)
  if (aUnreadMention && !bUnreadMention) return -1
  if (!aUnreadMention && bUnreadMention) return 1

  // Items with notification === 'delegation' go first
  const aNotification = itemHelper.getNotification(a)
  const bNotification = itemHelper.getNotification(b)
  if (aNotification === 'delegation' && bNotification !== 'delegation') return -1
  if (aNotification !== 'delegation' && bNotification === 'delegation') return 1

  // Latest modified items go first
  const aTimestamp = itemHelper.getLastModifiedTimestamp(a)
  const bTimestamp = itemHelper.getLastModifiedTimestamp(b)
  if (aTimestamp > bTimestamp) return -1
  if (aTimestamp < bTimestamp) return 1
  return 0
}

export const useAlertItems = forceAlertId => {
  const alerts = useAttentionNeededInSession()
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const forced = useSelector(st => stateHelper.getItem(st, forceAlertId))
  const list = forced ? alerts.add(forced) : alerts

  const [previousOrder, setPreviousOrder] = useState([])

  const currentIds = new Set(list.map(item => itemHelper.getId(item)))
  const previousIds = new Set(previousOrder.map(item => itemHelper.getId(item)))

  // Check if we have the same set of IDs
  const hasSameIds = currentIds.size === previousIds.size && [...currentIds].every(id => previousIds.has(id))

  if (hasSameIds) {
    // Maintain previous order but use updated item data
    return previousOrder.map(prevItem => {
      const id = itemHelper.getId(prevItem)
      return allItems.get(id)
    })
  } else {
    const currentItems = list.toIndexedSeq().sort(compareItemsByMentionAndTimestamp).toArray()
    setPreviousOrder(currentItems)
    return currentItems
  }
}
