/**
 * Contains the status of the request that are in progress
 **/

import _ from 'lodash'
import { fromJS } from 'immutable'
import { API_EVENT, parseApiActionType, PERSIST_REHYDRATE_ACTION_TYPE } from '../helpers/reducerHelper'
import { REQUEST_REDUCER_KEYS as KEYS } from './requestReducerKeys'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'

const requestBase = fromJS({
  [KEYS.INITIALIZED]: false,
  [KEYS.PENDING_COUNT]: 0,
})

const base = fromJS({
  [KEYS.SYNC_TIMESTAMP]: 0,
})

const setSyncTimestamp = (state, reqMeta) => {
  const reqTimestamp = _.get(reqMeta, 'requested_time')
  if (reqTimestamp) {
    const reqTimestampNumber = _.toNumber(reqTimestamp)
    state = state.set(KEYS.SYNC_TIMESTAMP, reqTimestampNumber)
  }
  return state
}

const getResourceState = (state, api, resource, meta) => {
  const serverID = meta && (meta.projectID || meta.itemID)
  if (serverID) {
    const resourcePath = resource + `_${serverID}`
    return state.getIn([api, resourcePath]) || requestBase
  }
  return state.getIn([api, resource]) || requestBase
}

const updateResourceState = (state, resourceState, api, resource, meta) => {
  const serverID = meta && (meta.projectID || meta.itemID)
  if (serverID) {
    const resourcePath = resource + `_${serverID}`
    return state.setIn([api, resourcePath], resourceState)
  }
  return state.setIn([api, resource], resourceState)
}

export const request = (state = base, action) => {
  const { type, payload, meta } = action
  const apiActionType = parseApiActionType(type)
  if (apiActionType) {
    const { api, resource, event } = apiActionType
    if (meta && meta.skipRequestTracking) {
      return state
    }
    let resourceState = getResourceState(state, api, resource, meta)
    switch (event) {
      case API_EVENT.START:
        resourceState = resourceState.merge({
          [KEYS.PENDING_COUNT]: resourceState.get(KEYS.PENDING_COUNT) + 1,
          [KEYS.ERROR]: false,
        })
        state = updateResourceState(state, resourceState, api, resource, meta)
        break
      case API_EVENT.SUCCESS: {
        const reqMeta = _.get(payload, 'meta')
        const isSlowPolling = _.get(meta, 'params.timestamp__gte')
        const hasNext = reqMeta && reqMeta.next
        const initialized = !isSlowPolling && !hasNext
        resourceState = resourceState.merge({
          [KEYS.INITIALIZED]: initialized,
          [KEYS.PENDING_COUNT]: resourceState.get(KEYS.PENDING_COUNT) - 1,
          [KEYS.ERROR]: false,
        })
        if (reqMeta) {
          resourceState = resourceState.merge({
            // TODO: it only uses in one place
            next: reqMeta.next,
          })
          state = setSyncTimestamp(state, reqMeta)
        }
        state = updateResourceState(state, resourceState, api, resource, meta)
        break
      }
      case API_EVENT.ERROR:
        resourceState = resourceState.merge({
          [KEYS.ERROR]: true,
          [KEYS.PENDING_COUNT]: resourceState.get(KEYS.PENDING_COUNT) - 1,
        })
        state = updateResourceState(state, resourceState, api, resource, meta)
        break
      default:
        break
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.requests) {
            state = state.merge(payload.requests)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      case WS_ACTIONS.ITEM_RECEIVED:
        {
          const item = payload
          const timestamp = _.toNumber(item.timestamp)
          state = state.set(KEYS.SYNC_TIMESTAMP, timestamp)
        }
        break
    }
  }
  return state
}
