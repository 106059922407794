import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useShowToastDidCopyLinkToClipboard } from '../../hooks/clipboardHooks'
import React, { useCallback, useRef, useState } from 'react'
import { embedProjectView, getCSVExportURL, getProjectLink } from '../../common/src/constants'
import { projectHelper } from '../../common/src/helpers'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { copyToClipboard } from '../../common/src/helpers/clipboardUtils'
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components'
import {
  Alert,
  Archive,
  ArchiveArrowBack,
  ArrowDownload,
  ArrowUpload,
  BinRecycleFull,
  CalendarLtr,
  Code,
  Copy,
  Delete,
  DocumentOnePageLink,
  DocumentSave,
  Link as LinkIcon,
  Mail,
  MoreHorizontal,
  PersonSubtract,
  Pin,
  PinOff,
} from '../../components/BundledIcons'
import { ProjectSubscriptionsModal } from '../modal/ProjectSubscriptionsModal'
import { useExportToCSV, useImportCSVToProjectWithToast, useObtainCalendarLink } from '../../hooks/projectHooks'
import { ProjectCopySecretEmailModal } from '../../components/project/ProjectCopySecretEmailModal'
import { getAbsoluteURL, getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { simulateLinkClick } from '../../common/src/utils'
import { ProjectReadOnlyLinkModal } from '../../components/project/ProjectReadOnlyLinkModal'
import { DuplicateProjectDialog } from '../../components/DuplicateProjectDialog'
import { HiddenFileInput } from '../../components/input/HiddenFileInput'
import { Link } from 'react-router-dom'
import { addArchiveTag, removeArchiveTag } from '../../common/src/actions/tagsAPI'
import { AlertModal } from '../../components/modal/AlertModal'
import { DeleteProjectModal } from '../../components/modal/DeleteProjectModal'
import { useConfig } from '../../queries/config'
import { useMe } from '@/common/src/hooks/usersHooks.js'
import { sendProject } from '@/common/src/actions/combinedAPI'

export const ProjectOptionsButton = ({ project, readOnly }) => (
  <ProjectOptionsMenu
    project={project}
    readOnly={readOnly}
    trigger={
      <MenuTrigger>
        <MenuButton
          data-testid="ProjectDetail_OptionsButton"
          icon={<MoreHorizontal />}
          appearance="subtle"
          className="self-start"
        />
      </MenuTrigger>
    }
  />
)

export const ProjectOptionsMenu = ({ project, readOnly, trigger, open, onOpenChange, positioning }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const [isProjectSubscriptionsModalOpen, setIsProjectSubscriptionsModalOpen] = useState(false)
  const [isProjectCopySecretEmailModalOpen, setIsProjectCopySecretEmailModalOpen] = useState(false)
  const [isReadOnlyLinkModalOpen, setIsReadOnlyLinkModalOpen] = useState(false)
  const [isDuplicateProjectDialogOpen, setIsDuplicateProjectDialogOpen] = useState(false)
  const [isOpenArchiveProjectModal, setIsOpenArchiveProjectModal] = useState(false)
  const [isOpenDeleteProjectModal, setIsOpenDeleteProjectModal] = useState(false)
  const projectId = projectHelper.getIdd(project)
  const projectName = projectHelper.getName(project)
  const csvFileInputRef = useRef(null)
  const isArchived = project && projectHelper.isArchived(project)
  const config = useConfig().data
  const hipaa = config?.hipaa
  const canUseSecretInbox = config?.inbox ?? true
  const me = useMe()
  const imAdmin = projectHelper.isAdminUser(project, me)
  const owners = projectHelper.getOwnersURIs(project)
  const isPinned = projectHelper.isPinned(project)

  const copyLink = useCallback(() => {
    const link = getProjectLink(projectHelper.getIdd(project))
    dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.COPY_PROJECT_LINK))
    copyToClipboard(link)
    showToastDidCopyLinkToClipboard()
  }, [dispatch, project, showToastDidCopyLinkToClipboard])

  const handleModifySubscriptions = useCallback(() => setIsProjectSubscriptionsModalOpen(true), [])
  const handleCloseProjectSubscriptionsModal = () => setIsProjectSubscriptionsModalOpen(false)

  const { copyCalendarLink } = useObtainCalendarLink(projectId)

  const importCSVToProject = useImportCSVToProjectWithToast()

  const openEmbedView = useCallback(() => {
    const viewLink = getAbsoluteURL.oneProjectMatrixForProjectId(projectId)
    const externalLink = embedProjectView({
      title: projectName,
      link: viewLink,
    })
    simulateLinkClick(externalLink, true)
  }, [projectId, projectName])

  const urlExportToCSV = getCSVExportURL(projectId)
  const downloadCSV = useExportToCSV(project)

  const exportToCSV = useCallback(
    event => {
      event.preventDefault()
      downloadCSV()
    },
    [downloadCSV]
  )

  const importCSV = useCallback(() => {
    csvFileInputRef.current.click()
  }, [])

  const onChangeCSVFile = evt => {
    const files = evt.target.files
    const file = files[0]
    importCSVToProject({ file, projectID: projectId })
    // Clean input value
    csvFileInputRef.current.value = null
  }

  const unarchiveProject = useCallback(() => {
    dispatch(removeArchiveTag({ object: project }))
  }, [dispatch, project])

  const archiveProject = useCallback(() => {
    dispatch(addArchiveTag({ object: project }))
  }, [dispatch, project])

  const onDeleteProject = () => {
    setIsOpenDeleteProjectModal(false)
  }

  const onPinProject = () => {
    const newProject = project.set(projectHelper.KEYS.PINNED, !isPinned)
    dispatch(sendProject(newProject))
  }

  return (
    <>
      <Menu open={open} onOpenChange={onOpenChange} positioning={positioning}>
        {trigger}
        <MenuPopover>
          <MenuList>
            <MenuItem onClick={copyLink} icon={<LinkIcon />}>
              {t('project.menu.copy_link')}
            </MenuItem>
            <MenuItem onClick={onPinProject} icon={isPinned ? <PinOff /> : <Pin />}>
              {t(isPinned ? 'project.menu.unpin_project' : 'project.menu.pin_project')}
            </MenuItem>
            <MenuItem onClick={handleModifySubscriptions} icon={<Alert />}>
              {t('project.menu.modify_subscriptions')}
            </MenuItem>
            <MenuItem onClick={copyCalendarLink} icon={<CalendarLtr />}>
              {t('project_detail.copy_calendar_link')}
            </MenuItem>
            {/* eslint-disable-next-line no-constant-binary-expression */}
            {false && ( //Disabled due to our changes is CSP.
              <MenuItem onClick={openEmbedView} icon={<Code />}>
                {t('project.menu.embed')}
              </MenuItem>
            )}
            {!readOnly && canUseSecretInbox && (
              <MenuItem onClick={() => setIsProjectCopySecretEmailModalOpen(true)} icon={<Mail />}>
                {t('project.menu.secret_email')}
              </MenuItem>
            )}
            {!readOnly && !hipaa && (
              <MenuItem onClick={() => setIsReadOnlyLinkModalOpen(true)} icon={<DocumentOnePageLink />}>
                {t('project.menu.share_in_readonly')}
              </MenuItem>
            )}
            <MenuItem onClick={() => setIsDuplicateProjectDialogOpen(true)} icon={<Copy />}>
              {t('project.menu.duplicate_project')}
            </MenuItem>
            <MenuItem
              as="a"
              href={urlExportToCSV}
              onClick={exportToCSV}
              className="no-underline"
              target="_blank"
              icon={<ArrowDownload />}
            >
              {t('project.menu.export_csv')}
            </MenuItem>
            {!readOnly && (
              <MenuItem onClick={importCSV} icon={<ArrowUpload />}>
                {t('project.menu.import_csv')}
              </MenuItem>
            )}
            {!readOnly && (
              <Link
                to={getRelativeURLKeepingQuerySearch.newCustomProjectTemplateApp(projectId)}
                className="no-underline"
              >
                <MenuItem icon={<DocumentSave />}>{t('project.menu.save_as_template')}</MenuItem>
              </Link>
            )}
            {!readOnly && (
              <Link to={getRelativeURLKeepingQuerySearch.restoreItemsForProjectId(projectId)} className="no-underline">
                <MenuItem icon={<BinRecycleFull />}>{t('project.menu.restore_deleted_items')}</MenuItem>
              </Link>
            )}
            <MenuDivider />
            {isArchived && !readOnly && (
              <MenuItem onClick={unarchiveProject} icon={<ArchiveArrowBack />}>
                {t('project.menu.unarchive')}
              </MenuItem>
            )}
            {!isArchived && !readOnly && (
              <MenuItem onClick={() => setIsOpenArchiveProjectModal(true)} icon={<Archive />}>
                {t('project.menu.archive')}
              </MenuItem>
            )}
            {!readOnly && (
              <MenuItem
                onClick={() => setIsOpenDeleteProjectModal(true)}
                icon={imAdmin || owners?.size === 1 ? <Delete /> : <PersonSubtract />}
                data-testid="ProjectDetail_DeleteProject"
              >
                {t(
                  owners?.size === 1
                    ? 'project.menu.delete'
                    : imAdmin
                      ? 'project.menu.leave_or_delete'
                      : 'project.menu.leave'
                )}
              </MenuItem>
            )}
          </MenuList>
        </MenuPopover>
      </Menu>
      {!!projectId && (
        <ProjectSubscriptionsModal
          projectId={projectId}
          isOpen={isProjectSubscriptionsModalOpen}
          onClose={handleCloseProjectSubscriptionsModal}
          onSave={handleCloseProjectSubscriptionsModal}
        />
      )}
      <ProjectCopySecretEmailModal
        isOpen={isProjectCopySecretEmailModalOpen}
        project={project}
        onDismiss={() => setIsProjectCopySecretEmailModalOpen(false)}
      />
      <ProjectReadOnlyLinkModal
        hidden={!isReadOnlyLinkModalOpen}
        projectID={projectId}
        projectName={projectName}
        onDismiss={() => setIsReadOnlyLinkModalOpen(false)}
      />
      <DuplicateProjectDialog
        project={project}
        open={isDuplicateProjectDialogOpen}
        setOpen={setIsDuplicateProjectDialogOpen}
      />
      <HiddenFileInput id="csvFile" onChange={onChangeCSVFile} ref={csvFileInputRef} accept=".csv" />
      <AlertModal
        title={t('project_detail.archive_alert_title')}
        subText={t('project_detail.archive_alert_subtitle')}
        primaryActionText={t('general.archive')}
        onPrimaryActionClick={archiveProject}
        open={isOpenArchiveProjectModal}
        onDismiss={() => setIsOpenArchiveProjectModal(false)}
      />
      <DeleteProjectModal open={isOpenDeleteProjectModal} project={project} onDismiss={onDeleteProject} />
    </>
  )
}
