import styled from 'styled-components'

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${p => p.secondaryColor};
  color: var(--color-theme-primary);
  border: 1px solid var(--color-theme-primary);
  padding: 0 3px;
  box-sizing: border-box;
  border-radius: 4px;

  font-size: 12px;

  cursor: pointer;
  &.readOnly {
    cursor: inherit;
  }
`
