import { useDispatch, useSelector } from 'react-redux'
import { getLastWebinarIdReminded, getWebinarReminderEnabled } from '../selectors/uiSelectors'
import { useEffect } from 'react'
import { useWebinars } from '../queries/webinars'
import { Button, ToastTrigger, useToastController } from '@fluentui/react-components'
import { FluentToast } from './toast/FluentToast'
import { setLastWebinarIdReminded } from '../actions/uiActions'
import { useTranslation } from 'react-i18next'
import { captureLinkClick } from '@/utils/externalLinkHandler'
import { differenceInCalendarDays, differenceInMinutes } from 'date-fns'
import { type Webinar } from '@/types/webinar'

const BEFORE_MARGIN = 24 * 60 * 60 * 1000 // 24 hours
const AFTER_MARGIN = 15 * 60 * 1000 // 15 minutes

interface ComputeActionParams {
  webinar: Webinar
  now: Date
  webinarDate: Date
}

interface ComputeActionResult {
  title: string
  href: string
}

const computeAction = ({ webinar, now, webinarDate }: ComputeActionParams): ComputeActionResult => {
  const minutesDiff = differenceInMinutes(webinarDate, now)
  if (minutesDiff < 60) {
    return {
      title: 'webinar_reminder.join',
      href: webinar.join_link ?? webinar.registration_link,
    }
  }
  return {
    title: 'webinar_reminder.register',
    href: webinar.registration_link ?? webinar.join_link,
  }
}

export const WebinarReminder = () => {
  const webinarReminderEnabled = useSelector(getWebinarReminderEnabled) as boolean
  const lastWebinarIdReminded = useSelector(getLastWebinarIdReminded) as undefined | number
  const { data: webinars } = useWebinars(webinarReminderEnabled)
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()

  useEffect(() => {
    if (!webinars || !webinarReminderEnabled) return
    // If a webinar starts in the next hour or has started in the past 15 minutes, show a toast
    const nowDate = new Date()
    const now = nowDate.getTime()
    const webinar = webinars.find(webinar => {
      const diff = +webinar.event_datetime * 1000 - now
      return diff < BEFORE_MARGIN && diff > -AFTER_MARGIN
    })
    if (!webinar || webinar.id === lastWebinarIdReminded) return
    const webinarDate = new Date(+webinar.event_datetime * 1000)
    const action = computeAction({ webinar, now: nowDate, webinarDate })
    dispatchToast(
      <FluentToast
        footer={
          <div className="flex flex-col gap-1">
            <ToastTrigger>
              <Button as="a" appearance="primary" href={action.href} target="_blank" onClick={captureLinkClick()}>
                {t(action.title)}
              </Button>
            </ToastTrigger>
            <ToastTrigger>
              <Button appearance="secondary">{t('webinar_reminder.pass_for_now')}</Button>
            </ToastTrigger>
          </div>
        }
      >
        {webinarDate.getTime() - now < 0
          ? t('webinar_reminder.title_past')
          : t('webinar_reminder.title', {
              webinarName: webinar.topic,
              webinarTime: formatRelativeDateTime(webinarDate, nowDate),
            })}
      </FluentToast>,
      { timeout: -1 }
    )
    dispatch(setLastWebinarIdReminded(webinar.id))
  }, [dispatch, dispatchToast, lastWebinarIdReminded, t, webinarReminderEnabled, webinars])

  return false
}

const formatRelativeDateTime = (date: Date, baseDate = new Date()) => {
  const locale = navigator.language
  const diffDays = differenceInCalendarDays(date, baseDate)

  const relativeFormatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })
  const timeFormatter = new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: '2-digit' })

  const relativeDay = relativeFormatter.format(diffDays, 'day')
  const time = timeFormatter.format(date)

  return `${relativeDay} at ${time}`
}
