export const ITEM_KEYS = {
  ALL_DAY: 'allDay',
  CREATION_DATE: 'creationDate',
  CREATOR_USERNAME: 'creator_username',
  COMPLETED_BY_USERNAME: 'completed_by_username',
  COMPLETION_DATE: 'completionDate',
  COMPLETION_PERCENTAGE: 'completionPercentage',
  COPIED_FROM_ID: 'copiedFromID',
  DELEGATED_BY_USERNAME: 'delegated_by_username',
  DESCRIPTION_TEXT: 'descriptionText',
  DUE_DATE: 'dueDate',
  EDITED_BY_USERNAME: 'edited_by_username',
  EFFORT: 'effort',
  ESTIMATED_EFFORT: 'estimatedEffort',
  FILES: 'files',
  FOLLOWERS: 'followers',
  FOLLOWING: 'following',
  FREQUENCY: 'frequency',
  ICON: 'icon',
  ID: 'id',
  INDEX: 'index',
  LAST_READ: 'last_read',
  LAST_ATTENTION: 'last_attention',
  LAST_COMMENT_ID: 'last_comment_id',
  LAST_MODIFIED_TIMESTAMP: 'lastModifiedTimestamp',
  LINKED_RESOURCES: 'linked_resources',
  LINKS: 'associated_links',
  MAC_RESOURCE_URL: 'macResourceURL',
  NAME: 'name',
  OWNER: 'owner',
  OWNER_USERNAME: 'owner_username',
  PROJECTS: 'projects',
  QUADRANT: 'quadrant',
  REALIZED_EFFORT: 'realizedEffort',
  RELATED_ITEMS: 'related_items',
  REMINDER: 'reminder',
  REMINDER_DATE: 'reminder_date',
  REMINDER_FREQUENCY: 'repeat_after',
  REMINDER_ALL_FOLLOWERS: 'remind_all_followers',
  REMINDER_NOTES: 'notes',
  RESOURCE_URI: 'resource_uri',
  STATE: 'state',
  START_DATE: 'startDate',
  TAGS: 'tags',
  TIMESTAMP: 'timestamp',
  UNTIL_DATE: 'untilDate',
  VERSION_ID: 'version_id',
  REMINDER_ID: 'id',
  PUBLIC_LINK: 'public_link',
  HAS_UNREAD_MENTION: 'has_unread_mention',
  NOTIFICATION: 'notification',
  RECURRENCE: 'recurrence',
  RECURRENCE_TEXT: 'recurrence_text',
  CHILD_ID: 'childID',
  PARENT_ID: 'parentID',
}
