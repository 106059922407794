import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { ReactNode, useEffect, useState } from 'react'
import { ProjectsDropdown } from '@/components/dropdown/ProjectsDropdown'
import { itemHelper, projectHelper, stateHelper } from '@/common/src/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { sendItem } from '@/common/src/actions/combinedAPI'
import { useTranslation } from 'react-i18next'
import { getProjectLink } from '@/common/src/constants'
import { useLinkedProjectId } from '@/hooks/itemHooks'
import { ProjectLinkSelectorDialogContext } from '@/typedContexts'

export type ProjectLinkSelectorDialogProps = {
  open: boolean
  onDismiss: () => void
  itemId: number
}

export const ProjectLinkSelectorDialog = ({ open, onDismiss, itemId }: ProjectLinkSelectorDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const item = useSelector(state => stateHelper.getItem(state, itemId))
  const linkedProjectId = useLinkedProjectId(item)
  const linkedProject = useSelector(state => stateHelper.getProject(state, linkedProjectId))
  const [selectedProject, setSelectedProject] = useState(linkedProject)

  const onSave = () => {
    const projectLink = selectedProject ? getProjectLink(projectHelper.getIdd(selectedProject)) : null
    dispatch(sendItem(item.set(itemHelper.KEYS.MAC_RESOURCE_URL, projectLink)))
  }

  const handleClear = () => {
    dispatch(sendItem(item.set(itemHelper.KEYS.MAC_RESOURCE_URL, null)))
  }

  useEffect(() => {
    console.log('ProjectLinkSelectorDialog useEffect', { open, linkedProject })
    if (open) {
      setSelectedProject(linkedProject)
    }
  }, [open, linkedProject])

  return (
    <Dialog
      open={open}
      onOpenChange={(e, data) => {
        if (!data.open) {
          onDismiss()
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('item_link_to_project_dialog.title')}</DialogTitle>
          <DialogContent>
            <ProjectsDropdown
              selectedProject={selectedProject}
              onProjectChanged={setSelectedProject}
              enableInbox={false}
              projects={undefined}
              label={undefined}
              disabled={undefined}
              id={undefined}
              className={undefined}
            />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">{t('general.close')}</Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={handleClear}>
                {t('general.clear')}
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary" onClick={onSave}>
                {t('general.save')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

export type ProjectLinkSelectorDialogSingletonProps = {
  children: ReactNode
}
export const ProjectLinkSelectorDialogSingleton = ({ children }: ProjectLinkSelectorDialogSingletonProps) => {
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState(null)
  return (
    <ProjectLinkSelectorDialogContext.Provider
      value={{
        openDialog: item => {
          setItem(item)
          setOpen(true)
        },
      }}
    >
      <ProjectLinkSelectorDialog open={open} onDismiss={() => setOpen(false)} itemId={itemHelper.getId(item)} />
      {children}
    </ProjectLinkSelectorDialogContext.Provider>
  )
}
