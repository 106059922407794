export const localProjectTemplateKeys = {
  basic: 'basic',
  brainDump: 'brainDump',
  eisenhower: 'eisenhower',
  gtdMethod: 'gtdMethod',
  swotAnalysis: 'swotAnalysis',
  portersFourCornersModel: 'portersFourCornersModel',
  retroactiveProjectAnalysis: 'retroactiveProjectAnalysis',
}

export const localProjectTemplates = {
  [localProjectTemplateKeys.basic]: {
    key: localProjectTemplateKeys.basic,
    name: 'Basic Priority Matrix',
    textFirstQuadrant: 'High Priority',
    textSecondQuadrant: 'Medium Priority',
    textThirdQuadrant: 'Low Priority',
    textFourthQuadrant: 'Uncategorized',
    centerX: 50,
    centerY: 50,
    tooltip: 'Basic Priority Matrix project',
    notes:
      'Basic Priority Matrix will allow you to prioritize with a straight forward method simply based off of what is most important to you.',
  },
  [localProjectTemplateKeys.brainDump]: {
    key: localProjectTemplateKeys.brainDump,
    name: 'Brain Dump',
    textFirstQuadrant: 'Everything',
    textSecondQuadrant: 'Ideas',
    textThirdQuadrant: 'To Dos',
    textFourthQuadrant: 'Things to Read',
    centerX: 100,
    centerY: 0,
    tooltip: 'A simple project where you can just write all your ideas down.',
    notes:
      'The Brain Dump is a the perfect template to utilize for those on-the-go thoughts.  Whether you are in a meeting brainstorming ideas, or traveling and using Priority Matrix on your mobile device, use the brain dump as a place to store your ideas which you have not fully developed into a project yet.',
  },
  [localProjectTemplateKeys.eisenhower]: {
    key: localProjectTemplateKeys.eisenhower,
    name: 'Eisenhower Matrix',
    textFirstQuadrant: 'Critical and Immediate',
    textSecondQuadrant: 'Critical but not Immediate',
    textThirdQuadrant: 'Not Critical but Immediate',
    textFourthQuadrant: 'Uncategorized',
    centerX: 50,
    centerY: 50,
    tooltip: 'Tackle your priorities based on what is critical and immediate so that you only do what matters.',
    notes:
      'The Eisenhower Matrix is the core of Priority Matrix, allowing you to Prioritize based off of what is critical and urgent.  Prioritize your tasks from a date driven and criticality level perspective.',
  },
  [localProjectTemplateKeys.gtdMethod]: {
    key: localProjectTemplateKeys.gtdMethod,
    name: 'GTD Method',
    textFirstQuadrant: 'Time Driven - Critical & High Priority',
    textSecondQuadrant: 'Energy Driven - Critical & Low Effort',
    textThirdQuadrant: 'Critical & High Effort',
    textFourthQuadrant: 'Collection Bin',
    centerX: 50,
    centerY: 50,
    tooltip: "Classify all your daily tasks according to the criteria defined by David Allen's popular methodology.",
    notes:
      'Getting Things Done method, defined by David Allen, allows you to prioritize your tasks based off of what is date drive vs what tasks will take the most time/effort.',
  },
  [localProjectTemplateKeys.swotAnalysis]: {
    key: localProjectTemplateKeys.swotAnalysis,
    name: 'SWOT Analysis',
    textFirstQuadrant: 'Strength',
    textSecondQuadrant: 'Weakness',
    textThirdQuadrant: 'Opportunity',
    textFourthQuadrant: 'Threats',
    centerX: 50,
    centerY: 50,
    tooltip:
      'Analyze business ventures and potential projects by identifying the factors that influence the chance of success in achieving your objectives.',
    notes:
      'Use this SWOT analysis template to analyze subjects ranging from competitors to products. Create a clear breakdown of different aspects of each subject in order to make the strongest decision or changes for yourself and your company.',
  },
  [localProjectTemplateKeys.portersFourCornersModel]: {
    key: localProjectTemplateKeys.portersFourCornersModel,
    name: "Porter's Four Corners Model",
    textFirstQuadrant: 'Motivation - Drivers',
    textSecondQuadrant: 'Motivation - Assumptions',
    textThirdQuadrant: 'Actions - Strategy',
    textFourthQuadrant: 'Actions - Capabilities',
    centerX: 50,
    centerY: 50,
    tooltip: "Determine a competitor's course of action by analyzing what are their motivations and assumptions.",
    notes:
      'This template is purely built for analyzing a competitor or client in order to predict or plan for the future.  If you find yourself unsure of what your subjects goals are, take a look at their potential motivations and action strategies.',
  },
  [localProjectTemplateKeys.retroactiveProjectAnalysis]: {
    key: localProjectTemplateKeys.retroactiveProjectAnalysis,
    name: 'Retroactive Project Analysis',
    textFirstQuadrant: 'Planned & Successful',
    textSecondQuadrant: 'Unplanned & Successful',
    textThirdQuadrant: 'Planned & Failed',
    textFourthQuadrant: 'Unplanned & Failed',
    centerX: 50,
    centerY: 50,
    tooltip: 'Conduct a post-mortem analysis of your project strategy to see what you did right and wrong.',
    notes:
      'After all is said and done, use this template to review how a project, period of time, or goal was reached and  executed.  Be honest with yourself as you spend time analyzing each phase of the topic.',
  },
}
