import { forwardRef, useCallback, useMemo, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { itemHelper, projectHelper, tagHelper } from '../../common/src/helpers'
import { ItemTagsSelectorModal, ProjectTagsSelectorModal } from '../modal/TagsSelectorModal'
import { Tag, TagMenuKeys } from './Tag'
import { TagPlaceholder } from './TagPlaceholder'
import { removeTag } from '../../common/src/actions/tagsAPI'
import { RenameTagModal } from '../modal/RenameTagModal'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { fadeIn } from '../../style/scAnimations'
import { cn } from '@appfluence/classnames'
import { useFilterByTag } from '@/hooks/tagHooks'
import { TagContainer } from '@/components/tags/TagContainer'

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
`

const STag = styled(Tag)`
  animation: ${props =>
    props.fadeIn === true
      ? css`
          ${fadeIn} 0.5s ease-out
        `
      : 'none'};
`

const defaultStyles = {
  tagsContainer: {},
}

export const TagDetails = forwardRef(
  (
    {
      object,
      className,
      styles = defaultStyles,
      placeholderTagProps,
      tagProps,
      readOnly = false,
      selected,
      hidePlaceholderWhenEmpty = false,
      fadeIn = false,
      ...rest
    },
    ref
  ) => {
    const [state, localDispatch] = useReducer(reducer, { ...initialState })
    const dispatch = useDispatch()
    const { isOpenTagsSelector, isHiddenRenameTagModal, tagToRename } = state
    const isProject = projectHelper.isProject(object)
    const model = isProject ? PM_API_RESOURCE_TYPE.PROJECT : PM_API_RESOURCE_TYPE.ITEM
    const objectTags = useMemo(() => {
      if (!object) {
        return []
      }
      let tags = null
      if (isProject) {
        tags = projectHelper.getTags(object).toArray()
      } else {
        // isItem
        tags = itemHelper.getTags(object).toArray()
      }
      return _.uniqBy(tags, tagHelper.getName)
    }, [isProject, object])
    const [animateTags] = useState(objectTags.length === 0)

    const showTagsSelector = useCallback(() => localDispatch({ type: ACTION_TYPE.SHOW_TAGS_SELECTOR }), [])
    const hideTagsSelector = useCallback(() => localDispatch({ type: ACTION_TYPE.HIDE_TAGS_SELECTOR }), [])
    const showRenameTagModal = useCallback(
      tag =>
        localDispatch({
          type: ACTION_TYPE.SHOW_RENAME_TAG_MODAL,
          payload: tag,
        }),
      []
    )
    const hideRenameTagModal = useCallback(() => localDispatch({ type: ACTION_TYPE.HIDE_RENAME_TAG_MODAL }), [])

    const onAddNew = useCallback(
      ev => {
        ev && ev.stopPropagation()
        showTagsSelector()
      },
      [showTagsSelector]
    )

    const filterByTag = useFilterByTag(isProject ? 'project' : 'item')

    const TagsSelector = useMemo(() => (isProject ? ProjectTagsSelectorModal : ItemTagsSelectorModal), [isProject])

    const onClickMenuOption = useCallback(
      (key, tag) => {
        switch (key) {
          case TagMenuKeys.ADD_NEW:
            {
              onAddNew()
            }
            break
          case TagMenuKeys.REMOVE:
            {
              const tagName = tagHelper.getName(tag)
              dispatch(removeTag({ tagName, object }))
            }
            break
          case TagMenuKeys.RENAME_FOR_ALL:
            {
              showRenameTagModal(tag)
            }
            break
          case TagMenuKeys.FILTER_BY:
            {
              filterByTag(tag)
            }
            break
          default:
            break
        }
      },
      [dispatch, onAddNew, showRenameTagModal, filterByTag, object]
    )

    const elementList = _.map(objectTags, (t, k) => {
      return (
        <STag
          key={k}
          tag={t}
          onClickMenuOption={onClickMenuOption}
          readOnly={readOnly}
          isProject={isProject}
          fadeIn={fadeIn && animateTags}
          {...tagProps}
        />
      )
    })

    const { tagsContainer } = styles
    const finalPlaceholderTagProps = { ...tagProps, ...placeholderTagProps }
    const shouldShowPlaceholder = !readOnly && _.isEmpty(elementList) && (!hidePlaceholderWhenEmpty || selected)
    return (
      <div ref={ref} className={cn('flex', className)} {...rest}>
        <TagsContainer style={tagsContainer}>
          {elementList.length > 0 && elementList}
          {elementList.length > 0 && <TagContainer onClick={onAddNew}>+</TagContainer>}
          {shouldShowPlaceholder && <TagPlaceholder onClick={onAddNew} {...finalPlaceholderTagProps} />}
        </TagsContainer>
        <TagsSelector object={object} isBlocking={true} isOpen={isOpenTagsSelector} onDismiss={hideTagsSelector} />
        <RenameTagModal
          hidden={isHiddenRenameTagModal}
          onDismiss={hideRenameTagModal}
          tag={tagToRename}
          model={model}
        />
      </div>
    )
  }
)

const ACTION_TYPE = {
  SHOW_TAGS_SELECTOR: 'SHOW_TAGS_SELECTOR',
  HIDE_TAGS_SELECTOR: 'HIDE_TAGS_SELECTOR',

  SHOW_RENAME_TAG_MODAL: 'SHOW_RENAME_TAG_MODAL',
  HIDE_RENAME_TAG_MODAL: 'HIDE_RENAME_TAG_MODAL',
}

const initialState = {
  isOpenTagsSelector: false,
  isHiddenRenameTagModal: true,
  tagToRename: null,
}

export const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.SHOW_TAGS_SELECTOR:
      state.isOpenTagsSelector = true
      break
    case ACTION_TYPE.HIDE_TAGS_SELECTOR:
      state.isOpenTagsSelector = false
      break
    case ACTION_TYPE.SHOW_RENAME_TAG_MODAL:
      state.isHiddenRenameTagModal = false
      state.tagToRename = payload
      break
    case ACTION_TYPE.HIDE_RENAME_TAG_MODAL:
      state.isHiddenRenameTagModal = true
      break
    default:
      break
  }
  return { ...state }
}
