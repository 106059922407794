import { useEffect, useRef, useState } from 'react'
import { projectHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'
import { sortBy } from '@/utils/arrayUtils'
import { useProjectsMap } from '@/common/src/hooks/projectHooks.js'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys.js'
import { Combobox, useComboboxFilter, useId } from '@fluentui/react-components'
import { QuadrantSelector } from '@/components/input/quadrant/QuadrantSelector'
import { cn } from '@/modules/classnames'

const useProjectToName = enableInbox => {
  const { t } = useTranslation()
  const keepName = t('item.multiple_selection.move.keep_same_project')
  const inboxName = t('general.inbox')
  return project =>
    project === 'keep' ? keepName : project ? projectHelper.getName(project) : enableInbox ? inboxName : ''
}

export const ProjectsDropdown = ({
  enableInbox = false,
  enableKeepSame = false,
  selectedProject,
  projects: givenProjects,
  onProjectChanged,
  label,
  disabled,
  id,
  className,
}) => {
  const comboId = useId('combobox')
  const inputRef = useRef(null)
  const projectsMap = useProjectsMap(true, FILTER_REDUCER_KEYS.PROJECTS_PANEL)
  const projects = givenProjects || projectsMap.toArray()
  const { t } = useTranslation()

  const keepName = t('item.multiple_selection.move.keep_same_project')
  const inboxName = t('general.inbox')

  const options = []
  if (enableKeepSame) {
    options.push({
      key: 'keep',
      text: keepName,
    })
  }
  if (enableInbox) {
    options.push({
      key: 'inbox',
      text: inboxName,
    })
  }
  const sortedProjects = sortBy(projects, projectHelper.sortDescriptorByName)
  options.push(
    ...sortedProjects.map(p => ({
      key: `${projectHelper.getIdd(p)}`,
      text: projectHelper.getName(p),
      data: { project: p },
    }))
  )
  const projectsOptions = options.map(option => ({
    value: option.key,
    children: (
      <div className="flex items-center gap-2">
        <QuadrantSelector size={20} project={projectsMap.get(+option.key)} readOnly />
        {option.text}
      </div>
    ),
  }))

  const projectToName = useProjectToName(enableInbox)

  const [searchQuery, setSearchQuery] = useState(projectToName(selectedProject))
  const [userHasTyped, setUserHasTyped] = useState(false)

  const keyToName = key =>
    key === 'inbox' ? inboxName : key === 'keep' ? keepName : key ? (projectsMap.get(+key)?.get('name') ?? '') : ''

  useEffect(() => {
    setSearchQuery(projectToName(selectedProject))
  }, [projectToName, selectedProject])

  const children = useComboboxFilter(userHasTyped ? searchQuery : '', projectsOptions, {
    noOptionsMessage: t('projects_dropdown.no_matches'),
    filter: (option, query) => {
      const optionName = keyToName(option)
      return optionName.toLowerCase().includes(query.toLowerCase())
    },
  })

  const onOptionSelect = (ev, data) => {
    if (data.optionValue === undefined) {
      return
    }
    onProjectChanged(
      data.optionValue === 'keep'
        ? 'keep'
        : data.optionValue === 'inbox'
          ? undefined
          : projectsMap.get(+data.optionValue)
    )
    setSearchQuery(keyToName(data.optionValue))
    setUserHasTyped(false)
  }

  const selectedKey =
    selectedProject === 'keep' ? 'keep' : selectedProject ? `${projectHelper.getIdd(selectedProject)}` : 'inbox'

  return (
    <div className="flex w-full flex-col gap-1.5">
      <label className="font-semibold text-black dark:text-white" htmlFor={id ?? comboId}>
        {label}
      </label>
      <Combobox
        ref={inputRef}
        placeholder={t('projects_dropdown.search_placeholder')}
        selectedOptions={[selectedKey]}
        onOptionSelect={onOptionSelect}
        onChange={ev => {
          setUserHasTyped(true)
          setSearchQuery(ev.target.value)
        }}
        value={searchQuery}
        className={cn('!mb-0 min-h-8 w-full', className)}
        onFocus={() => {
          inputRef.current?.select()
        }}
        onBlur={() => {
          setUserHasTyped(false)
          setSearchQuery(projectToName(selectedProject))
        }}
        disabled={disabled}
        id={id ?? comboId}
      >
        {children}
      </Combobox>
    </div>
  )
}
