import _ from 'lodash'
import { createAction } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { createPaginatedAPIAction } from '../helpers/paginationHelper'
import { itemHelper } from '../helpers'
import {
  appendQueryParamsToURL,
  extendPMHeaders,
  getCredentialsConfig,
  withDefaultParams,
} from '../helpers/requestHelper'
import { List } from 'immutable'

const DEFAULT_PARAMETERS = {
  INBOX_PLUS: {
    state__lte: 1,
    summaries: 1,
    limit: 500,
    order_by: '-creationDate',
  },
  ITEM_SUMMARIES: {
    state__lte: 1,
    limit: 200,
    order_by: '-lastModifiedTimestamp',
  },
  ITEM_SUMMARIES_IN_PROJECT: {
    state__lte: 1,
    limit: 1000,
  },
  ATTENTION_NEEDED: {
    returnfollowers: 1,
    summaries: 1,
    state__lte: 1,
    limit: 500,
    add_notification: true,
  },
  ALL_ITEMS: {
    returnfollowers: 1,
    state__lte: 1,
    limit: 1000,
  },
}

export const getAllItems = withDefaultParams(
  createPaginatedAPIAction(params =>
    createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_ITEMS, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ALL_ITEMS),
      headers: extendPMHeaders(),
    })
  ),
  DEFAULT_PARAMETERS.ALL_ITEMS
)

export const getItemsInProject = createPaginatedAPIAction((params = {}, projectID) => {
  const _url = _.template(PM_API_URLS.ITEMS_IN_PROJECT)({ idd: projectID })
  params = {
    ...params,
    state__lte: 1,
  }
  return createAction({
    endpoint: appendQueryParamsToURL(_url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEMS_IN_PROJECT, { projectID }),
    headers: extendPMHeaders(),
  })
})

export const getAllItemSummaries = withDefaultParams(
  createPaginatedAPIAction(params =>
    createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.ITEM_SUMMARIES, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_SUMMARIES),
      headers: extendPMHeaders(),
    })
  ),
  DEFAULT_PARAMETERS.ITEM_SUMMARIES
)

export const getItemSummaries = withDefaultParams(
  params =>
    createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.ITEM_SUMMARIES, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_SUMMARIES),
      headers: extendPMHeaders(),
    }),
  DEFAULT_PARAMETERS.ITEM_SUMMARIES
)

const _getItemSummariesInProject = createPaginatedAPIAction((params, projectID, { skipRequestTracking } = {}) => {
  if (skipRequestTracking === undefined) {
    skipRequestTracking = false
  }
  const _url = _.template(PM_API_URLS.ITEM_SUMMARIES_IN_PROJECT)({ idd: projectID })
  return createAction({
    endpoint: appendQueryParamsToURL(_url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_SUMMARIES_IN_PROJECT, { projectID, skipRequestTracking }),
    headers: extendPMHeaders(),
  })
})

export const getItemSummariesInProject = withDefaultParams(
  _getItemSummariesInProject,
  DEFAULT_PARAMETERS.ITEM_SUMMARIES_IN_PROJECT
)

export const getItem = (itemID, params = {}) => {
  const _url = _.template(PM_API_URLS.ITEM)({ id: itemID })
  const _params = {
    returnfollowers: 1,
    ...params,
  }
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM, { itemID })
  return createAction({
    endpoint: appendQueryParamsToURL(_url, _params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: types,
    headers: extendPMHeaders(),
  })
}

export const getRelatedItems = itemID => {
  const _url = _.template(PM_API_URLS.RELATED_ITEMS)({ id: itemID })
  return createAction({
    endpoint: appendQueryParamsToURL(_url),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.RELATED_ITEMS, { itemID }),
    headers: extendPMHeaders(),
  })
}

export const putItem = item => {
  const itemID = itemHelper.getId(item)
  if (itemID) {
    const hash = item.hashCode()
    const url = _.template(PM_API_URLS.ITEM)({ id: itemID })
    const body = itemHelper.prepareItemForPut(item)
    return createAction({
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'PUT',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_PUT, { itemID, hash }),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const type = 'WRONG_ITEM_PUT'
    console.warn(type)
    return { type }
  }
}

export const putItems = items => {
  items = List(items)
  const itemIDs = items.map(item => itemHelper.getId(item))
  const url = PM_API_URLS.ALL_ITEMS
  const body = itemHelper.prepareItemsForPut(items)
  const hashes = items.toMap().mapKeys((key, item) => itemHelper.getId(item))
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'PUT',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEMS_PUT, { itemIDs, hashes }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

export const postItem = (item, copiedFromID) => {
  if (item) {
    const name = item.get('name')
    if (!name) {
      const ERROR = 'ITEM_HAS_NO_NAME'
      console.warn(ERROR)
      return { type: ERROR }
    }
    const body = JSON.stringify(item.toJS())
    return createAction({
      endpoint: appendQueryParamsToURL(
        copiedFromID ? _.template(PM_API_URLS.ITEM_COPY)({ id: copiedFromID }) : PM_API_URLS.ALL_ITEMS
      ),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_POST),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const noItem = 'NO_ITEM_TO_POST'
    console.warn(noItem)
    return { type: noItem }
  }
}

export const postItems = items => {
  if (!items) {
    const noItems = 'NO_ITEMS_TO_POST'
    console.warn(noItems)
    return { type: noItems }
  }
  const body = JSON.stringify(items.map(item => item.toJS()))
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_ITEMS),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEMS_POST),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

export const inviteGroupToItemWithId = (itemID, groupID) => {
  const url = _.template(PM_API_URLS.INVITE_FOLLOWER_TO_ITEM)({ id: itemID })
  const params = { groupid: groupID }
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'PUT',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.INVITE_FOLLOWER_TO_ITEM, { itemID, groupID }),
    headers: extendPMHeaders(),
  })
}

export const inviteToItemWithId = (itemID, email) => {
  const url = _.template(PM_API_URLS.INVITE_FOLLOWER_TO_ITEM)({ id: itemID })
  const params = { useremail: email }
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'PUT',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.INVITE_FOLLOWER_TO_ITEM, { itemID, email }),
    headers: extendPMHeaders(),
  })
}

export const uninviteToItemWithId = (itemID, email) => {
  const url = _.template(PM_API_URLS.UNINVITE_FOLLOWER_TO_ITEM)({ id: itemID })
  const params = { useremail: email }
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'PUT',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.UNINVITE_FOLLOWER_TO_ITEM, { itemID, email }),
    headers: extendPMHeaders(),
  })
}

const _getAttentionNeeded = params =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.ATTENTION_NEEDED, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ATTENTION_NEEDED),
    headers: extendPMHeaders(),
  })

export const getAttentionNeeded = createPaginatedAPIAction(_getAttentionNeeded, DEFAULT_PARAMETERS.ATTENTION_NEEDED)

const _getInboxPlus = resource => params =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.INBOX_PLUS, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(resource),
    headers: extendPMHeaders(),
  })

export const getPartialInboxPlus = withDefaultParams(
  _getInboxPlus(PM_API_RESOURCES.INBOX_PLUS_PARTIAL),
  DEFAULT_PARAMETERS.INBOX_PLUS
)
export const getInboxPlus = withDefaultParams(_getInboxPlus(PM_API_RESOURCES.INBOX_PLUS), DEFAULT_PARAMETERS.INBOX_PLUS)

export const getFullInboxPlus = createPaginatedAPIAction(
  _getInboxPlus(PM_API_RESOURCES.INBOX_PLUS),
  DEFAULT_PARAMETERS.INBOX_PLUS
)

export const postAttachEmail = (itemID, mail) => {
  if (itemID) {
    const url = _.template(PM_API_URLS.ATTACH_EMAIL_TO_ITEM)({ id: itemID })
    const body = JSON.stringify(mail)
    return createAction({
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ATTACH_EMAIL),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const type = 'WRONG_ITEM_ATTACH_EMAIL'
    console.warn(type)
    return { type }
  }
}

export const getMakeItemPublic = itemId => {
  const url = _.template(PM_API_URLS.MAKE_ITEM_PUBLIC)({ id: itemId })
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MAKE_ITEM_PUBLIC),
    headers: extendPMHeaders(),
  })
}

export const getMakeItemPrivate = itemId => {
  const url = _.template(PM_API_URLS.MAKE_ITEM_PRIVATE)({ id: itemId })
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MAKE_ITEM_PUBLIC),
    headers: extendPMHeaders(),
  })
}

export const getFollowedItemsIdSet = projectID => {
  const url = _.template(PM_API_URLS.FOLLOWED_ITEMS)({ idd: projectID })
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.FOLLOWED_ITEMS, { projectID }),
    headers: extendPMHeaders(),
  })
}
export const getOneDriveFolderForItem = id => {
  const url = _.template(PM_API_URLS.FILE_ONEDRIVE_FOLDER)({ id })
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.FILE_ONEDRIVE_FOLDER, { id }),
    headers: extendPMHeaders(),
  })
}
