import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ACTION_TYPE } from '../CreationModalCommon'
import { setLastDueTimestampCreated, setLastStartTimestampCreated } from '../../../actions/uiActions'
import { LocalizedDatePickerV9 } from '../../../components/pickers/LocalizedDatePicker'
import { TimePickerCombo } from '../../../components/timepicker/TimePickerCombo'
import { Button, Checkbox } from '@fluentui/react-components'
import { DialogFooter } from '@fluentui/react/lib/Dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { DateShortcuts } from '@/components/dates/DateShortcuts'

const MotionLocalizedDatePicker = motion(LocalizedDatePickerV9)
const MotionTimePickerCombo = motion(TimePickerCombo)

export const DateSection = memo(({ state, localDispatch, lastDue, lastStart }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Sync last dates selected in Redux
  useEffect(() => {
    if (state.dueDate) {
      dispatch(setLastDueTimestampCreated(state.dueDate.getTime()))
    }
  }, [dispatch, state.dueDate])
  useEffect(() => {
    if (state.startDate) {
      dispatch(setLastStartTimestampCreated(state.startDate.getTime()))
    }
  }, [dispatch, state.startDate])

  const onChangeDueDateTime = d => {
    localDispatch({
      type: ACTION_TYPE.SET_DUE_DATE,
      payload: d,
    })
  }
  const onChangeStartDateTime = d => {
    localDispatch({
      type: ACTION_TYPE.SET_START_DATE,
      payload: d,
    })
  }

  const onChangeDueDate = d => {
    if (d) {
      // If there isn't a last due date stored, use current time
      lastDue ??= new Date()
      d.setHours(lastDue.getHours(), lastDue.getMinutes())
    }
    onChangeDueDateTime(d)
  }
  const onChangeStartDate = d => {
    if (d) {
      // If there isn't a last start date stored, use current time
      lastStart ??= new Date()
      d.setHours(lastStart.getHours(), lastStart.getMinutes())
    }
    onChangeStartDateTime(d)
  }

  const onChangeAllDay = checked => {
    localDispatch({
      type: ACTION_TYPE.SET_ALL_DAY,
      payload: checked,
    })
  }

  const onClickClear = () => {
    localDispatch({
      type: ACTION_TYPE.CLEAR_DATES,
    })
  }
  const onClickConfirm = () => {
    localDispatch({
      type: ACTION_TYPE.GO_BACK_TO_CREATION,
    })
  }
  return (
    <>
      <div className="mx-auto flex w-fit flex-col items-start">
        <MotionButton
          layout="position"
          appearance="transparent"
          className="!-mr-3 !min-w-0 self-end"
          onClick={onClickClear}
          disabled={!state.dueDate}
        >
          {t('general.clear')}
        </MotionButton>
        <div className="flex w-full flex-col justify-start gap-3 md:flex-row md:items-end">
          <MotionLocalizedDatePicker
            className="!flex-1"
            layout="position"
            data-automation-id="createItemModal-dueDate"
            value={state.dueDate}
            label={t('date.due_date')}
            onSelectDate={onChangeDueDate}
          />
          <AnimatePresence mode="popLayout" initial={false}>
            {!state.allDay && (
              <MotionTimePickerCombo
                key="timepicker"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="!min-w-0"
                value={state.dueDate ?? lastDue}
                onChange={onChangeDueDateTime}
                disabled={!state.dueDate}
              />
            )}
          </AnimatePresence>
        </div>
        <CreateItemDateShortcuts
          onDateChange={onChangeDueDate}
          onDateTimeChange={onChangeDueDateTime}
          onAllDayChange={onChangeAllDay}
        />
        <div className="mt-3 flex w-full flex-col justify-start gap-3 md:flex-row md:items-end">
          <MotionLocalizedDatePicker
            layout="position"
            value={state.startDate}
            label={{ children: t('date.start_date'), disabled: !state.dueDate }}
            onSelectDate={onChangeStartDate}
            disabled={!state.dueDate}
          />
          <AnimatePresence mode="popLayout" initial={false}>
            {!state.allDay && (
              <MotionTimePickerCombo
                key="timepicker"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="!min-w-0"
                value={state.startDate ?? lastStart}
                onChange={onChangeStartDateTime}
                disabled={!state.startDate}
              />
            )}
          </AnimatePresence>
        </div>
        <CreateItemDateShortcuts
          onDateChange={onChangeStartDate}
          onDateTimeChange={onChangeStartDateTime}
          onAllDayChange={onChangeAllDay}
          disabled={!state.dueDate}
        />
        <motion.div layout="position">
          <Checkbox
            className="mt-3"
            label={t('date.all_day')}
            checked={state.allDay}
            onChange={(ev, data) => onChangeAllDay(data.checked)}
          />
        </motion.div>
      </div>
      <motion.div layout="position">
        <DialogFooter>
          <Button
            data-automation-id="createItemModal-back"
            layout="position"
            appearance="primary"
            onClick={onClickConfirm}
          >
            {t('general.back')}
          </Button>
        </DialogFooter>
      </motion.div>
    </>
  )
})

const CreateItemDateShortcuts = ({ onDateChange, onDateTimeChange, onAllDayChange, disabled }) => {
  return (
    <MotionDateShortcuts
      layout="position"
      className="-ml-1 mt-1 self-start"
      onDateChange={onDateChange}
      onDateTimeChange={onDateTimeChange}
      onAllDayChange={onAllDayChange}
      disabled={disabled}
    />
  )
}

const MotionDateShortcuts = motion(DateShortcuts)

const MotionButton = motion(Button)
