/**
 *  Utils to interact with immutable project
 **/

import _ from 'lodash'
import { List } from 'immutable'
import { pickPredicate, safeMapFnCreator } from './immutableHelpers'
import { getProtocolLinkForProjectWithId, PM_API_RESOURCE_TYPE } from '../constants'
import { resourceURICreator, resourceURIParser, uriDataCreator } from './URLHelper'
import { arrayToRGBA, quadrantColorStringToArray } from './colorsHelpers'
import userHelper from './userHelper'
import pMembHelper from './projectMembershipHelper'
import sharingRequestHelper from './sharingRequestHelper'
import tagHelper from './tagHelper'
import { allDayDateStringRelativeToToday, toDate } from './dateHelper'
import projectMembershipHelper from './projectMembershipHelper'
import { isBefore } from 'date-fns'
import { createSelector } from 'reselect'

let helper = {}

const KEYS = {
  CREATION_DATE: 'creationDate',
  COLOR_FIRST_QUADRANT: 'colorFirstQuadrant',
  COLOR_SECOND_QUADRANT: 'colorSecondQuadrant',
  COLOR_THIRD_QUADRANT: 'colorThirdQuadrant',
  COLOR_FOURTH_QUADRANT: 'colorFourthQuadrant',
  EDITED_BY: 'edited_by',
  END_DATE: 'endDate',
  IDD: 'idd',
  INDEX: 'index',
  MEMBERSHIPS: 'memberships',
  NAME: 'name',
  NOTES: 'notes',
  OWNER: 'owner',
  RESOURCE_URI: 'resource_uri',
  STATE: 'state',
  START_DATE: 'startDate',
  TAGS: 'tags',
  TIMESTAMP: 'timestamp',
  TEXT_FIRST_QUADRANT: 'textFirstQuadrant',
  TEXT_SECOND_QUADRANT: 'textSecondQuadrant',
  TEXT_THIRD_QUADRANT: 'textThirdQuadrant',
  TEXT_FOURTH_QUADRANT: 'textFourthQuadrant',
  USER_GROUP_ID: 'user_group_id',
  VERSION_ID: 'version_id',
  INBOX_ADDRESSES: 'inbox_addresses',
  CREATOR: 'creator',
  CREATOR_USERNAME: 'creator_username',
  PINNED: 'pinned',
}
helper.KEYS = KEYS

helper.STATE = {
  NORMAL: 0,
  TRASH_FOR_EVERYBODY: 1,
  TRASH: 2,
  DELETED: 3,
}

helper.QUADRANT_COLORS = [
  KEYS.COLOR_FIRST_QUADRANT,
  KEYS.COLOR_SECOND_QUADRANT,
  KEYS.COLOR_THIRD_QUADRANT,
  KEYS.COLOR_FOURTH_QUADRANT,
]

helper.QUADRANT_NAMES = [
  KEYS.TEXT_FIRST_QUADRANT,
  KEYS.TEXT_SECOND_QUADRANT,
  KEYS.TEXT_THIRD_QUADRANT,
  KEYS.TEXT_FOURTH_QUADRANT,
]

helper.isProject = o => {
  const resourceUri = helper.getResourceURI(o)
  const r = resourceURIParser(resourceUri)
  return (
    !o.get('id') && r && (r.type === PM_API_RESOURCE_TYPE.PROJECT || r.type === PM_API_RESOURCE_TYPE.PROJECT_SUMMARY)
  )
}

helper.getIdd = p => p.get(KEYS.IDD)

helper.getState = p => p.get(KEYS.STATE)
helper.isDeleted = p => p.get(KEYS.STATE) > helper.STATE.NORMAL
helper.getIndex = p => p.get(KEYS.INDEX)

helper.getName = p => p.get(KEYS.NAME)
helper.getNotes = p => p.get(KEYS.NOTES)

helper.getResourceURI = p => p.get(KEYS.RESOURCE_URI) || helper.generateResourceUri(p)
helper.getUriData = p => uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, helper.getIdd(p))
helper.generateResourceUri = p => resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, helper.getIdd(p))

helper.getTags = p => {
  const tags = p.get(KEYS.TAGS)
  if (!tags) {
    return List()
  }
  const uniqueTags = tags
    .groupBy(tag => helper.getName(tag))
    .map(group => group.first())
    .toList()
  return uniqueTags
}

helper.isArchived = p => {
  const tags = helper.getTags(p)
  return tags && tags.some(t => tagHelper.getName(t) === tagHelper.ARCHIVED_TAG_NAME)
}

helper.isStarred = p => {
  const tags = helper.getTags(p)
  return tags && tags.some(t => tagHelper.getName(t) === tagHelper.STARRED_TAG_NAME)
}

helper.isOwner = (p, userURI) => {
  const userID = _.get(resourceURIParser(userURI), 'id')
  if (!userID) return false
  const o = helper.getOwnersIDs(p)
  return !!o.find(id => id === userID)
}
helper.getOwnersURIs = p => {
  const uris = p.get(KEYS.OWNER)
  return uris ? uris : List()
}
helper.getOwnersIDs = createSelector(
  p => p,
  p => {
    const uris = helper.getOwnersURIs(p)
    return uris.map(uri => resourceURIParser(uri).id)
  }
)

helper.getQuadrantColor = (p, n) => p.get(helper.QUADRANT_COLORS[n])

helper.getQuadrantColorRGBA = (p, n) => {
  const color = helper.getQuadrantColor(p, n)
  const components = quadrantColorStringToArray(color)
  return arrayToRGBA(components)
}

helper.getQuadrantName = (p, q) => p.get(helper.QUADRANT_NAMES[q])

helper.getSafeQuadrantName = (p, q, untitledText) => {
  const quadrantName = _.trim(helper.getQuadrantName(p, q), ' \n')
  if (_.isEmpty(quadrantName)) {
    return untitledText ? untitledText : `Q${q + 1} - Untitled`
  }
  return quadrantName
}

helper.getTimestamp = (p, type = KEYS.TIMESTAMP) => {
  const timestamp = p.get(type)
  return timestamp ? timestamp : 0
}

helper.getStartTimestamp = p => helper.getTimestamp(p, KEYS.START_DATE)
helper.getEndTimestamp = p => helper.getTimestamp(p, KEYS.END_DATE)
helper.getStartDate = p => toDate(helper.getTimestamp(p, KEYS.START_DATE))
helper.getEndDate = p => toDate(helper.getTimestamp(p, KEYS.END_DATE))

// UI
helper.getEndTextFromNow = p => {
  const end = helper.getEndDate(p)
  return allDayDateStringRelativeToToday(end)
}

helper.getEndColor = (p, negativeColor) => {
  const end = helper.getEndDate(p)
  if (!end) {
    return null
  }

  if (isBefore(end, new Date())) {
    return negativeColor
  }
  return null
}

helper.getProtocolLink = p => getProtocolLinkForProjectWithId(helper.getIdd(p))

helper.getMemberships = p => p.get(KEYS.MEMBERSHIPS)
helper.membershipByUserID = (p, userID) => {
  if (!p || !userID) {
    return null
  }

  const memberships = helper.getMemberships(p)
  if (!memberships) {
    return null
  }

  return memberships.find(m => {
    const uri = pMembHelper.getUserURI(m)
    const id = resourceURIParser(uri).id
    return id === userID
  })
}
helper.membershipByUserEmail = (p, email) => {
  if (!p || !email) {
    return null
  }
  const memberships = helper.getMemberships(p)
  if (!memberships) {
    return null
  }

  return memberships.find(m => {
    const username = pMembHelper.getUsername(m)
    return username === email
  })
}

helper.membershipByUser = (p, u) => {
  const userEmail = userHelper.getEmail(u)
  return helper.membershipByUserEmail(p, userEmail)
}

helper.setUserRoleInMemberships = (p, u, role) => {
  const allMemberships = helper.getMemberships(p).toSet()
  let userMembership = helper.membershipByUser(p, u)
  const username = userHelper.getEmail(u)

  userMembership = projectMembershipHelper.setProjectMembershipRole(userMembership, role)

  const mappedAllMemberships = allMemberships.map(member => {
    let newMember
    const memberUsername = projectMembershipHelper.getUsername(member)
    if (memberUsername === username) {
      newMember = userMembership
    }
    return newMember || member
  })

  return mappedAllMemberships.toList()
}

helper.isAdminUserID = (p, userID) => {
  if (!p || !userID) {
    return false
  }

  const m = helper.membershipByUserID(p, userID)
  return pMembHelper.isProjectAdmin(m)
}
helper.isAdminUser = (p, u) => {
  const userID = userHelper.getID(u)
  return helper.isAdminUserID(p, userID)
}

helper.getCreatorURI = p => p.get(KEYS.CREATOR)
helper.getCreatorUsername = p => p.get(KEYS.CREATOR_USERNAME)

helper.getUserGroupID = p => {
  return p.get(KEYS.USER_GROUP_ID)
}

helper.isPinned = p => p.get(KEYS.PINNED)

helper.sortDescriptorByName = p => _.toLower(helper.getName(p))

const PUT_WHITELIST = [
  KEYS.CREATION_DATE,
  KEYS.COLOR_FIRST_QUADRANT,
  KEYS.COLOR_SECOND_QUADRANT,
  KEYS.COLOR_THIRD_QUADRANT,
  KEYS.COLOR_FOURTH_QUADRANT,
  KEYS.END_DATE,
  KEYS.IDD,
  KEYS.INDEX,
  KEYS.NAME,
  KEYS.NOTES,
  KEYS.RESOURCE_URI,
  KEYS.STATE,
  KEYS.START_DATE,
  KEYS.TEXT_FIRST_QUADRANT,
  KEYS.TEXT_SECOND_QUADRANT,
  KEYS.TEXT_THIRD_QUADRANT,
  KEYS.TEXT_FOURTH_QUADRANT,
  KEYS.USER_GROUP_ID,
  KEYS.VERSION_ID,
  KEYS.PINNED,
]
helper.prepareProjectForPut = p => {
  const obj = p.filter(pickPredicate(...PUT_WHITELIST)).toJS()
  return JSON.stringify(obj)
}

helper.emailFromOwnerOrRequest = ownerOrRequest => {
  if (sharingRequestHelper.isSharingRequest(ownerOrRequest)) {
    return sharingRequestHelper.getTargetUsername(ownerOrRequest)
  } else {
    // user
    return userHelper.getEmail(ownerOrRequest)
  }
}

helper.getSecretEmailAddress = (project, quadrant = 0, user) => {
  const projectID = helper.getIdd(project)
  const userEmail = userHelper.getEmail(user)
  const inboxAddresses = helper.getInboxAddresses(project)
  if (!projectID || !inboxAddresses || !userEmail) {
    return null
  }
  const quadrantEmails = inboxAddresses[quadrant]
  return quadrantEmails[userEmail]
}

helper.getInboxAddresses = project => {
  return project.get(KEYS.INBOX_ADDRESSES)
}

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default helper
