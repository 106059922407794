import React, { useState } from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { Link } from '@fluentui/react/lib/Link'
import { Icon } from '@fluentui/react/lib/Icon'
import { AttributePlaceholder } from '../placeholder/AttributePlaceholder'
import { LinkModal } from '../modal/LinkModal'
import { ICON_NAMES } from '../../common/src/msIcons'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { hasProtocol } from '../../common/src/helpers/URLHelper'
import { captureLinkClick } from '../../utils/externalLinkHandler'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, MenuList, MenuPopover } from '@fluentui/react-components'
import { useProjectLinkSelectorDialog } from '@/typedContexts'
import { projectHelper } from '@/common/src/helpers'

const EditIcon = styled(Icon).attrs({
  iconName: 'Edit',
})`
  display: block;
  margin-right: 4px;
  color: ${p => p.theme.palette.neutralTertiary};
  cursor: pointer;
  order: 1;
`

const StyledLink = styled(Link)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 11px;
  white-space: nowrap;
  order: 2;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  margin: 2px;

  @media (hover: hover) and (pointer: fine) {
    &:hover ${EditIcon} {
      color: ${p => p.theme.palette.themePrimary};
    }
  }
`

const AddTagPlaceholder = styled(AttributePlaceholder).attrs({
  iconName: ICON_NAMES.Link,
})`
  cursor: pointer;
  &.readOnly {
    cursor: inherit;
  }
`

export const ItemLinkAttribute = ({ item, link, onChange, readOnly = false, linkedProject }) => {
  const [showLinkModal, setShowLinkModal] = useState(false)
  const readOnlyClassName = cn({ readOnly })
  const { openDialog: openProjectLinkDialog } = useProjectLinkSelectorDialog()
  const [containerElement, setContainerElement] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { t } = useTranslation()

  const onClick = captureLinkClick(urlData => {
    linkSubject.next({ urlData, source: SOURCES.ITEM_RESOURCES })
  })
  const sanitizeLink = hasProtocol(link) ? link : 'https://' + link
  return (
    <Container ref={setContainerElement}>
      {link ? (
        <>
          <EditIcon onClick={readOnly ? undefined : () => setIsMenuOpen(true)} />
          <StyledLink title={link} onClick={onClick} href={sanitizeLink} target="_blank" rel="noopener noreferrer">
            {linkedProject ? `Project: ${projectHelper.getName(linkedProject)}` : link}
          </StyledLink>
        </>
      ) : (
        <AddTagPlaceholder
          id={'itemDetail_addLinkPlaceholder'}
          name={t('item_detail.add_link')}
          onClick={readOnly ? undefined : () => setIsMenuOpen(true)}
          className={readOnlyClassName}
        />
      )}
      <LinkModal
        isBlocking={true}
        isOpen={showLinkModal}
        onDismiss={() => {
          setShowLinkModal(false)
        }}
        link={link}
        onChange={onChange}
      />
      <Menu
        positioning={{ target: containerElement }}
        open={isMenuOpen}
        onOpenChange={(e, data) => setIsMenuOpen(data.open)}
      >
        <MenuPopover>
          <MenuList>
            <MenuItem onClick={() => setShowLinkModal(true)}>{t('item_detail.set_link_custom')}</MenuItem>
            <MenuItem onClick={() => openProjectLinkDialog(item)}>{t('item_detail.set_link_project')}</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </Container>
  )
}
