import {
  useState,
  useDebugValue,
  useRef,
  useCallback,
  startTransition,
  memo,
  useEffect,
  useDeferredValue,
  useMemo,
} from 'react'
import { cn } from '@appfluence/classnames'
import styled, { useTheme } from 'styled-components'
import { itemHelper, stateHelper, projectHelper } from '../../common/src/helpers'
import { IconCell } from '../icon/IconCell'
import { ProgressBar } from '../basic/ProgressBar'
import { useUserForEmail } from '../../common/src/hooks/usersHooks'
import { UserCollectionCell } from '../users/usersCollection/UserCollectionCell'
import { useCellStyles } from './ItemCellStyles'
import { Button, Checkbox as MsCheckbox } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
import { DirectionalHint } from '@fluentui/react/lib/Callout'
import { ItemContextualMenu } from '../item/ItemContextualMenu'
import { useLinkedProjectId, useMarkCompleted } from '../../hooks/itemHooks'
import { useMobile } from '../../helpers/responsiveHelpers'
import { useGetItemWhenOpenContextualMenu } from '../../hooks/itemContextualMenu'
import { useDispatch, useSelector } from 'react-redux'
import { markItemAsModified } from '../../common/src/actions/combinedAPI'
import { OwnerPanel } from '../item/OwnerPanel'
import { useUsersMenuProps } from '../../hooks/usersMenuPropsHooks'
import { getProjectIfNeeded } from '../../common/src/actions/combinedAPI'
import { SHOW_PROJECT_IN_ITEMS_CELL } from '../../environment'
import { defaultTheme } from '../../style/Palettes'
import { FlexColumn, FlexRow } from '../layout/FlexContainer'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { linkSubject } from '../../reactions/linkSubject'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import {
  getItemsDisplayMode,
  getItemSelectedInMultipleSelection,
  getItemsShowAvatars,
  isItemMultipleSelectionOn,
} from '../../selectors/uiSelectors'
import { setItemSelectedInMultipleSelection } from '../../actions/uiActions'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { usePositioningMouseTarget } from '@fluentui/react-positioning'
import { formatDate } from '../../utils/datefns'
import { StarFilled } from '@fluentui/react-icons'
import { useNarrowWidth } from '@/hooks/useNarrowWidth'
import { CompactDueDate } from '@/components/itemCell/CompactDueDate'
import { ItemDisplayMode } from '@/constants/ui'
import { useMutateItem } from '@/queries/items'
import { ArrowUpRight } from '@/components/BundledIcons'
import { useItemClick } from '@/hooks/PMHooks.js'
import { useAllProjectCounters } from '@/queries/projectCounters'
import bindEvents from '@hello-pangea/dnd/src/view/event-bindings/bind-events'

const ProjectText = styled.div`
  font-size: 10px;
  font-weight: lighter;
  color: ${p => (p.color ? p.color : p.theme.palette.black)};
  position: absolute;
  padding-top: 3px;
  bottom: ${p => (p.narrow ? 0 : 1)}px;
  right: 4px;

  @media print {
    display: none;
  }
`

const ProjectTextPrint = styled.div`
  display: none;
  font-size: 10px;
  font-weight: lighter;
  color: black;

  @media print {
    display: block;
  }
`

const DueText = styled.div`
  flex: 1;
  font-size: 10px;
  line-height: 16px;
  color: ${p => p.color ?? p.theme.palette.neutralDark};

  @media print {
    display: none;
  }
`

const DueTextPrint = styled(DueText)`
  display: none;
  @media print {
    display: block;
    color: ${p =>
      p.color === p.theme.palette.negative
        ? defaultTheme.palette.negative
        : p.color === p.theme.palette.affirmative
          ? defaultTheme.palette.affirmative
          : defaultTheme.palette.neutralDark};
  }
`

const Center = styled(FlexColumn)`
  flex: 1;
  min-width: 0;
  align-self: center;
`

const TopCenter = styled.div`
  display: flex;
`

const Star = ({ className }) => <StarFilled className={cn('text-base text-yellow-400', className)} />

// Right Detail
const RightDetailWidth = 28
const RightDetailHeight = 28
const RightDetailSpace = 4

const ProgressBarWidth = RightDetailWidth
const ProgressBarHeight = 5

const PercentageText = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${p => p.theme.palette.themePrimary};
  margin-bottom: ${RightDetailSpace}px;

  @media print {
    display: none;
  }
`

const ProgressBarStyled = styled(ProgressBar).attrs({
  height: ProgressBarHeight,
  width: ProgressBarWidth,
})`
  @media print {
    display: none;
  }
`

const ProgressPrint = styled.span`
  display: none;
  font-size: 12px;
  font-weight: bold;
  &:before {
    content: ' (';
  }
  &:after {
    content: '%)';
  }
  @media print {
    display: inline;
  }
`

const RightDetail = styled.div`
  float: right;
  margin-left: 5px;
`

const getCellBackground = ({ styles, isRead }) => {
  const defaultBackground = styles.background || 'inherit'
  return styles.readBackground && isRead ? styles.readBackground : defaultBackground
}

const CellContainer = styled(FlexRow)`
  border-radius: 4px;
  flex: 1;
  position: relative;
  background: ${p => getCellBackground(p)};
  text-size-adjust: 100%;

  @media (hover: hover) and (pointer: fine) {
    &.highlightHover:hover {
      cursor: pointer;
      background: ${p => p.styles.highlight};
    }
  }

  &.selected,
  // so that &.highlightHover:hover doesn't override this
  &.selected:hover {
    background: ${p => p.styles.selectedBackground};
  }

  @media print {
    background: none !important;
    break-inside: avoid !important;
    & + & {
      border-top-style: solid;
      border-top-width: 0.5px;
      border-top-color: rgba(100, 100, 100, 0.3);
    }
  }
`

const useItemIndexDebug = item => {
  useDebugValue(itemHelper.getIndex(item))
}

const UnreadMention = () => {
  const { t } = useTranslation()
  return (
    <div
      className="mr-1 inline-flex h-5 w-5 items-center justify-center rounded-full bg-pm-theme-primary"
      aria-label={t('alerts.unread_mention')}
    >
      <span className="text-l mt-[-2px] text-pm-white">@</span>
    </div>
  )
}

const AssignedToYou = () => {
  const { t } = useTranslation()
  return (
    <div className="mr-1 inline-flex h-5 items-center justify-center rounded-full bg-pm-theme-primary px-1.5">
      <span className="mt-[-2px] text-xs text-pm-white">{t('alerts.assigned_to_you')}</span>
    </div>
  )
}

const ItemCell_ = ({
  item,
  allItemsInList,
  onClick,
  onFocus,
  onStateChange,
  styles: stylesProp,
  onCheckboxChange: delegateOnCheckboxChange,
  selected = false,
  readOnly = false,
  isDragging = false,
  innerRef,
  idx,
  shouldShowProject = false,
  showOwner: _showOwner = true,
  ...rest
}) => {
  const elementRef = useRef()
  const setRef = useCallback(
    ref => {
      elementRef.current = ref
      innerRef?.(ref)
    },
    [innerRef]
  )
  const showOwner = useSelector(getItemsShowAvatars) && _showOwner && !isDragging
  const displayMode = useSelector(getItemsDisplayMode)
  const narrowScreen = useNarrowWidth()
  const narrow =
    displayMode === ItemDisplayMode.Compact || (displayMode === ItemDisplayMode.Default && narrowScreen) || isDragging
  useItemIndexDebug(item)
  const theme = useTheme()
  const defStyles = useCellStyles()
  const styles = stylesProp || defStyles
  const { t } = useTranslation()
  const id = itemHelper.getId(item)
  const done = itemHelper.isCompleted(item)
  const dispatch = useDispatch()
  const itemRef = useRef(null)
  const [isOpenOwnerView, setOpenOwnerView] = useState(false)

  const _handleClick = useCallback(() => onClick(item), [onClick, item])
  const handleClick = useItemClick({ allItems: allItemsInList, onClickItem: _handleClick, item })

  const { mutate: mutateItem } = useMutateItem(id)

  // Make item more narrow while dragging
  // https://github.com/hello-pangea/dnd/blob/8f8cc785/stories/src/mixed-sizes/mixed-size-lists-experiment.tsx
  useEffect(() => {
    return bindEvents(window, [
      {
        eventName: 'onBeforeCapture',
        fn: event => {
          const before = event.detail.before
          const mousePosition = event.detail.mousePosition
          if (before.mode !== 'FLUID' || +before.draggableId !== id || !elementRef.current) {
            return
          }
          const el = elementRef.current
          const box = el.getBoundingClientRect()
          const targetWidth = 80
          const halfWidth = targetWidth / 2
          const distanceToLeft = Math.max(mousePosition.x - box.left, 0)
          el.style.width = `${targetWidth}px`
          el.style.height = `${box.height}px`

          // Nothing left to do
          if (distanceToLeft < halfWidth) {
            return
          }

          // what the new left will be
          const proposedLeftOffset = distanceToLeft - halfWidth
          // what the raw right value would be
          const targetRight = box.left + proposedLeftOffset + targetWidth

          // how much we would be going past the right value
          const rightOverlap = Math.max(targetRight - box.right, 0)

          // need to ensure that we don't pull the element past
          // it's resting right position
          const leftOffset = proposedLeftOffset - rightOverlap

          el.style.position = 'relative'
          el.style.left = `${leftOffset}px`
        },
      },
    ])
  }, [id, innerRef])

  const changeOwnerProperty = useCallback(
    username => {
      if (!itemRef.current) {
        itemRef.current = item
      }
      itemRef.current = itemRef.current.set(itemHelper.KEYS.OWNER_USERNAME, username)

      const finalItem = itemRef.current
      startTransition(() => void dispatch(markItemAsModified(finalItem)))
      mutateItem(finalItem)
    },
    [dispatch, item, mutateItem]
  )

  const handleFocus = event => {
    // Only focus if user didn't press menu button
    onFocus?.(item, idx)
  }

  const handleMouseDown = event => {
    // If click wasn't done with left button, prevent focus
    if (event.button !== 0) event.preventDefault()
    if (event.shiftKey) event.preventDefault()
  }

  const { markCompleted: handleMarkCompleted } = useMarkCompleted(item)

  const name = itemHelper.getName(item)
  const starred = itemHelper.isStarred(item)
  const email = itemHelper.getOwnerUsername(item)
  const owner = useUserForEmail(email)

  const iconName = itemHelper.getIconNameSafelyWithoutExtension(item)
  const hasIcon = iconName !== itemHelper.EMPTY_ICON_WITHOUT_EXTENSION
  const progress = itemHelper.getCompletionPercentage(item)
  const hasProgress = progress > 0

  let dueText = itemHelper.getDueTextFromNow(item)
  const allDay = itemHelper.getAllDay(item)
  const dueDate = itemHelper.getDueDate(item)
  const printDueDateFormat = allDay ? 'ShortenedAllDay' : 'ShortenedNormal'
  const dueTextPrint = dueDate ? formatDate(printDueDateFormat)(dueDate) : undefined
  const dueColor = itemHelper.getDueColor(item, theme.palette.affirmative, theme.palette.negative)

  const checkboxTooltip = done ? t('item.progress.reset_completion') : t('item.progress.mark_completed')
  const bold = false //disabling right now since it's buggy
  const isRead = !bold
  const mobilePlatform = useMobile()
  const projectID = itemHelper.getProjectIdd(item)
  const project = useSelector(state => stateHelper.getProject(state, projectID))
  const projectName = projectHelper.getName(project)
  const ownerInArray = useMemo(() => [owner], [owner])
  const menuProps = useUsersMenuProps({ users: owner ? ownerInArray : null, object: item })
  const linkProjectId = useLinkedProjectId(item)
  const linkIsProject = linkProjectId !== null
  const linkProject = useSelector(state => stateHelper.getProject(state, linkProjectId))
  const { data: projectCounts } = useAllProjectCounters(!!linkProject)
  const multipleSelection = useSelector(isItemMultipleSelectionOn)
  const selectedInMultipleSelection = useSelector(getItemSelectedInMultipleSelection(id))

  const handleClickProjectLink = evt => {
    linkSubject.next({ urlData: uriDataCreator(1, 'project', linkProjectId) })
    evt.stopPropagation()
  }

  const showOwnerView = useCallback(
    event => {
      if (!mobilePlatform) {
        event.preventDefault()
        event.stopPropagation()
      }
      dispatch(getProjectIfNeeded({ projectID, memberships: true }))
      setOpenOwnerView(true)
    },
    [dispatch, mobilePlatform, projectID]
  )

  const hideOwnerView = useCallback(() => {
    setOpenOwnerView(false)
  }, [setOpenOwnerView])

  const showProject = shouldShowProject && SHOW_PROJECT_IN_ITEMS_CELL

  if (showProject && !dueText) {
    dueText = '\u200b' // Unicode zero-width caracter, just so that DueText has height
  }

  const handleMultipleSelectionChange = () => {
    dispatch(setItemSelectedInMultipleSelection(id, !selectedInMultipleSelection))
  }

  const checkboxBackgroundColor = selected ? defStyles.selectedBackground : defStyles.highlight

  const displayedName = linkIsProject && linkProject ? projectHelper.getName(linkProject) : name

  const compactSection = (() => {
    if (!narrow || isDragging) return null
    if (!dueDate && iconName === 'empty_orange128') return null
    if (!dueDate && iconName !== 'empty_orange128') return <IconCell icon={iconName} side={24} />
    if (dueDate && iconName === 'empty_orange128')
      return <CompactDueDate className="ml-1" dueDate={dueDate} allDay={allDay} />
    return (
      <div className="relative mr-1.5">
        <IconCell icon={iconName} side={24} className="" />
        <CompactDueDate className="!absolute -right-2 -top-1 ml-1" dueDate={dueDate} allDay={allDay} size="small" />
      </div>
      // <div className="relative">
      //   <IconCell icon={iconName} side={20} className="absolute -right-2.5 -top-1.5 z-10" />
      //   <CompactDueDate className="" dueDate={dueDate} />
      // </div>
    )
  })()

  const ownerPersonaProps = useMemo(
    () => ({
      avatar: {
        size: narrow ? 24 : 28,
        className: '!mr-0',
      },
    }),
    [narrow]
  )

  return (
    <CellContainer
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onFocus={handleFocus}
      className={cn('group', { selected, highlightHover: onClick })}
      aria-describedby={id}
      styles={styles}
      isRead={isRead}
      ref={setRef}
      data-item-cell={id}
      {...rest}
    >
      <div className="flex min-w-0 flex-1 items-end p-2">
        <Center>
          <TopCenter>
            {!isDragging && multipleSelection && (
              <Tooltip
                content={selectedInMultipleSelection ? t('general.unselect') : t('general.select')}
                relationship="label"
              >
                <MsCheckbox
                  className={`${narrow ? '!-mt-1' : '!-mt-0.5'} !mr-1`}
                  size={narrow ? 'large' : 'medium'}
                  checked={selectedInMultipleSelection}
                  onChange={handleMultipleSelectionChange}
                  onClick={evt => evt.stopPropagation()}
                />
              </Tooltip>
            )}
            {showOwner && (
              <div className="relative">
                <UserCollectionCell
                  className="mr-1 self-start"
                  user={owner}
                  onClick={readOnly ? undefined : showOwnerView}
                  personaProps={ownerPersonaProps}
                  hidePersonaDetails
                />
                {starred && <Star className="absolute -top-1 left-0" />}
              </div>
            )}
            {!mobilePlatform && (
              <OwnerPanel
                user={owner}
                project={project}
                onChange={changeOwnerProperty}
                isOpen={isOpenOwnerView}
                onDismiss={hideOwnerView}
                menuProps={menuProps}
              />
            )}
            <div className="min-w-0 flex-1">
              <div
                className={cn('flex w-full items-center', selected && '!text-pm-black', 'print:!text-pm-always-black')}
                style={{ color: styles.text }}
              >
                {itemHelper.hasUnreadMention(item) && <UnreadMention />}
                {starred && !showOwner && <Star className="mr-0.5" />}
                {!isDragging && linkIsProject && (
                  <QuadrantSelector
                    className="mr-1.5"
                    readOnly
                    size={30}
                    project={linkProject}
                    quadrant={-1}
                    showTooltips={false}
                    counters={projectCounts?.[linkProjectId]}
                  />
                )}
                <span
                  title={displayedName}
                  className={cn(
                    'min-w-0 flex-1',
                    isDragging && 'text-xs',
                    isDragging ? 'line-clamp-3' : narrow ? 'truncate' : 'line-clamp-2',
                    done && 'line-through'
                  )}
                >
                  {itemHelper.getNotification(item) === 'delegation' && <AssignedToYou />}
                  {displayedName}
                </span>
                {!isDragging && linkIsProject && (
                  <Tooltip relationship="label" content={t('item_detail.go_to_project')}>
                    <Button
                      appearance="subtle"
                      className="ml-1"
                      icon={<ArrowUpRight />}
                      onClick={handleClickProjectLink}
                    />
                  </Tooltip>
                )}
                {compactSection}
                {hasProgress && <ProgressPrint>{progress}</ProgressPrint>}
              </div>
              {showProject && <ProjectTextPrint>{projectName}</ProjectTextPrint>}
              {!narrow && dueText && (
                <DueText color={dueColor} narrow={narrow}>
                  {dueText}
                </DueText>
              )}
              {dueTextPrint && (
                <DueTextPrint color={dueColor} narrow={narrow}>
                  {dueTextPrint}
                </DueTextPrint>
              )}
              {!narrow && showProject && <ProjectText narrow={narrow}>{projectName}</ProjectText>}
            </div>
            {!narrow && (hasIcon || hasProgress) && (
              <RightDetail>
                {hasIcon && (
                  <IconCell
                    icon={iconName}
                    side={hasProgress ? RightDetailHeight - ProgressBarHeight : RightDetailHeight}
                  />
                )}
                {!hasIcon && hasProgress && <PercentageText progress={progress}>{`${progress}%`}</PercentageText>}
                {hasProgress && <ProgressBarStyled progress={progress} />}
              </RightDetail>
            )}
          </TopCenter>
        </Center>
        {!readOnly && !mobilePlatform && !multipleSelection && !linkIsProject && (
          <div
            style={{
              background: `linear-gradient(to right, color-mix(in srgb, ${checkboxBackgroundColor}, transparent 100%) 0%, color-mix(in srgb, ${checkboxBackgroundColor}, transparent 0%) 10%, color-mix(in srgb, ${checkboxBackgroundColor}, transparent 0%) 100%)`,
            }}
            className={cn(
              'absolute right-0 top-0 z-50 hidden h-full items-center justify-center rounded px-2 group-hover:flex',
              narrow && 'pr-1'
            )}
          >
            <Tooltip content={checkboxTooltip} relationship="label" directionalHint={DirectionalHint.rightCenter}>
              <MsCheckbox
                checked={done}
                onChange={() => handleMarkCompleted(done ? 0 : 100)}
                onClick={event => event.stopPropagation()}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </CellContainer>
  )
}

const MemoizedItemCellContent = memo(ItemCell_)

const ItemCellWithContextualMenu = ({ allItemsInList, ...props }) => {
  const { item, mobilePlatform, readOnly } = props

  const deferredAllItemsInList = useDeferredValue(allItemsInList)
  const [showContextualMenu, setShowContextualMenu] = useState(false)
  const [contextualMenuTarget, setContextualMenuTarget] = usePositioningMouseTarget()
  const dispatch = useDispatch()
  useGetItemWhenOpenContextualMenu(item, showContextualMenu)

  const handleShowContextualMenu = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      setContextualMenuTarget(event)
      setShowContextualMenu(true)
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ITEM_CONTEXTUAL_MENU_OPEN))
    },
    [dispatch, setContextualMenuTarget]
  )

  return (
    <>
      <MemoizedItemCellContent
        onContextMenu={handleShowContextualMenu}
        allItemsInList={deferredAllItemsInList}
        {...props}
      />
      {showContextualMenu && !readOnly && !mobilePlatform && (
        <ItemContextualMenu
          itemId={itemHelper.getId(item)}
          allItemsInList={allItemsInList}
          target={contextualMenuTarget}
          onOpenChange={(ev, data) => {
            setShowContextualMenu(data.open)
          }}
        />
      )}
    </>
  )
}

export const ItemCell = memo(ItemCellWithContextualMenu)
