import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
  Switch,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useConvertItemIntoProject } from '@/queries/items'
import { type ReactNode, useState } from 'react'
import { ConvertItemToProjectDialogContext } from '@/typedContexts'
import { linkSubject } from '@/reactions/linkSubject'
import { uriDataCreator } from '@/common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '@/common/src/constants'

type ConvertItemToProjectModalProps = {
  itemId: number
  open: boolean
  onClose: () => void
}
const ConvertItemToProjectModal = ({ itemId, open, onClose }: ConvertItemToProjectModalProps) => {
  const { t } = useTranslation()
  const { mutate: convertItemIntoProject, isPending } = useConvertItemIntoProject()
  const [useAI, setUseAI] = useState(false)

  return (
    <Dialog
      open={open}
      onOpenChange={(ev, data) => {
        if (!data.open) onClose()
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('item.convert_item_to_project_dialog.title')}</DialogTitle>
          <DialogContent>
            <div className="mb-2 flex flex-col">
              <Switch
                label={t('item.convert_item_to_project_dialog.use_ai')}
                checked={useAI}
                onChange={(ev, data) => setUseAI(data.checked)}
              />
              <span className="text-neutral-700 dark:text-neutral-400">
                ℹ️ {t('item.convert_item_to_project_dialog.ai_explanation')}
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button>{t('general.close')}</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={() => {
                convertItemIntoProject(
                  { itemId, useAI },
                  {
                    onSuccess: project => {
                      // @ts-ignore
                      linkSubject.next({ urlData: uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, project.idd) })
                      onClose()
                    },
                  }
                )
              }}
            >
              <div className="flex gap-2">
                {isPending && <Spinner size="tiny" />}
                {t('item.convert_item_to_project_dialog.convert')}
              </div>
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

export const ConvertItemToProjectModalSingleton = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState(0)
  return (
    <ConvertItemToProjectDialogContext.Provider
      value={{
        openDialog: (itemId: number) => {
          setOpen(true)
          setItemId(itemId)
        },
      }}
    >
      <ConvertItemToProjectModal itemId={itemId} open={open} onClose={() => setOpen(false)} />
      {children}
    </ConvertItemToProjectDialogContext.Provider>
  )
}
